
import {defineComponent, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n/index";
import {useRoute} from "vue-router";
import {version} from "@/core/helpers/documentation";
import {asideMenuIcons} from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import authenticationService from "@/core/services/AuthenticationService";
import eventBus from "@/core/services/eventBus";

export default defineComponent({
  name: "arc-menu",
  components: {},
  props: ['themeText'],
  methods: {
    menuItemClass(menuItem) {
      return menuItem.cssClass || '';
    },
    // Check if a menu item can be shown.
    canShow(menuItem) {
      // Skip this item if "excludedFeatures" contains one of the user's features.
      if (menuItem.excludedFeatures) {
        if (menuItem.excludedFeatures.some(feature => {
          return authenticationService.isFeatureEnabled(feature);
        })) return false;
      }

      if (!menuItem.requiredFeatures) return true;
      return menuItem.requiredFeatures.some(feature => {
          return authenticationService.isFeatureEnabled(feature);
      });
    },
    changeLanguage(lang) {
      localStorage.Lang = this.$i18n.locale = lang;
    },
    openModal(modal) {
      if (modal === 'help') eventBus.$emit('openHelp')
    }
  },
  computed: {
    loggedIn() {
      return authenticationService.isLoggedIn();
    },
    sisId() {
      let user = authenticationService.currentUser();
      if (user && user.id) {
        return user.id;
      }
      // If not a student, hide the SIS id.
      return null;
    },
    displayName() {
      let user = authenticationService.currentUser();
      if (user && user.profile) {
        return user.profile.display_name;
      }
      return '';
    },
    fullName() {
      let user = authenticationService.currentUser();
      if (user && user.fullName) {
        return user.fullName
      }
      return '';
    },
    avatar() {
      let avatar = "user";
      if (authenticationService.isLoggedIn()) {
        if (authenticationService.currentUser().profile) {
          const avatar_id = authenticationService.currentUser().profile.avatar_id;
          if (avatar_id != null) avatar = avatar_id;
        }
      }
      return "/media/avatars/" + avatar + ".png";
    }
  },
  setup() {
    const {t, te} = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      route,
      version,
      translate,
    };
  },
});
