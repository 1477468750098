
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import ArcMenu from "@/core/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import authenticationService from "@/core/services/AuthenticationService";

export default defineComponent({
  name: "Aside",
  components: {
    ArcMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  computed: {
    theme() {
      let baseColor = '163C64';
      if (authenticationService.isLoggedIn()) {
        baseColor = authenticationService.currentUser().profile.bg_color;
      }
      return {
        baseColor
      };
    },
    themeData() {
      let textColor = ''
      let themeName = ''
      switch (this.theme.baseColor) {
        case '163C64':
          textColor = '#94AAC2';
          themeName = 'default';
          break;
        case '046381':
          textColor = '#DCE7EA';
          themeName = 'ocean';
          break;
        case '0C5939':
          textColor = '#DBF0E7';
          themeName = 'forest';
          break;
        case '820C29':
          textColor = '#FAE5EA';
          themeName = 'burgundy';
          break;
        case '581D59':
          textColor = '#E5D1E5';
          themeName = 'purple';
          break;
        case 'B23370':
          textColor = '#FDF2F4';
          themeName = 'pink';
          break;
        case '1E1E1F':
          textColor = '#E3E3E3';
          themeName = 'dark';
          break;
        default:
          textColor = '#94AAC2';
          themeName = 'default';
          break;
      }
      return {
        textColor: textColor,
        themeName: themeName
      }
    }
  },
  setup() {
    const { t } = useI18n();

    return {
      asideTheme,
      t,
    };
  },
});
