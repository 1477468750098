import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "aside-logo-content" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArcMenu = _resolveComponent("ArcMenu")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_aside",
    class: _normalizeClass(["aside aside-hoverable", [
      _ctx.asideTheme === 'light' && 'aside-light',
      _ctx.asideTheme === 'dark' && 'aside-dark',
    ]]),
    "data-kt-drawer": "true",
    "data-kt-drawer-name": "aside",
    "data-kt-drawer-activate": "{default: true, lg: false}",
    "data-kt-drawer-overlay": "true",
    "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
    "data-kt-drawer-direction": "start",
    "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
  }, [
    _createElementVNode("div", {
      class: "aside-logo",
      id: "kt_aside_logo",
      style: _normalizeStyle({backgroundColor: '#'+_ctx.theme.baseColor })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          alt: "ARC Reads Logo",
          src: require('/public/media/logos/full-arc-reads-logo.png'),
          class: "aside-logo-image"
        }, null, 8, _hoisted_2)
      ])
    ], 4),
    _createElementVNode("div", {
      class: _normalizeClass(["aside-menu flex-column-fluid", _ctx.themeData.themeName]),
      style: _normalizeStyle({backgroundColor: '#'+_ctx.theme.baseColor})
    }, [
      _createVNode(_component_ArcMenu, {
        themeText: _ctx.themeData.textColor
      }, null, 8, ["themeText"])
    ], 6)
  ], 2))
}