const DocMenuConfig = [
  {
    pages: [
      {
        heading: "menu.home",
        route: "/home",
        svgIcon: "/media/icons/home.svg"
      },
      {
        heading: "menu.logbook",
        route: "/logbook",
        svgIcon: "/media/icons/book.svg"
      },
      {
        heading: "menu.assignments",
        route: "/assignments",
        svgIcon: "/media/icons/assignment.svg",
        requiredFeatures: ['arcreads-assignments']
      },
      {
        heading: "menu.assessment",
        route: "/myirla",
        svgIcon: "/media/icons/memo-circle-check-regular.svg",
        requiredFeatures: ['arcreads-myirla']
      },
      {
        heading: "menu.bookshelf",
        route: null,
        imageIcon: "/media/icons/bookshelf-icon.svg",
        externalLink: "https://arcbookshelf.americanreading.com/",
        cssClass: 'mt-auto'
      },
      {
        heading: "menu.help",
        route: null,
        modalLink: 'help',
        svgIcon: "/media/icons/question-circle.svg"
      },
      {
        heading: "menu.settings",
        route: "/profile",
        svgIcon: "/media/icons/settings.svg",
      },
      {
        heading: "menu.logout",
        route: "/logout",
        svgIcon: "/media/icons/logout.svg",
        //fontIcon: "bi-app-indicator",
      }
    ],
  },
];

export default DocMenuConfig;
